import React from "react";
import { Box, Flex } from "rebass";
import _ from "lodash";

import { faGoogleDrive } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import SEO from "../components/seo"

const textSizes = ["15px", "15px", "16px"];
const headTextSizes = ["24px", "28px", "32px"];
const smallTextSizes = ["12px", "12px", "14px"];
const titleTextSizes = ["14px", "18px", "20px"];
const tableItemSizes = ["15px", "15px", "17px"];

const myTeachingItems = [
  {
    name: "Econometrics I (graduate)",
    time: "Fall, 2017-2019",
    attachments: [
      "https://drive.google.com/drive/folders/1v-O25mvl-zEzK6lv1E4pAw80lzquS143?usp=sharing",
    ],
    instructors:
      "Instructors: Seyhan Erden; Mehmet Caner; Jushan Bai and Steven Olley",
  },
  {
    name: "Econometrics II (graduate)",
    time: "Fall, 2018-2020",
    attachments: [
      "https://drive.google.com/drive/folders/1LO9Aq-kD_568Em7R7MQA6sbhCw5tvD_w?usp=sharing",
    ],
    instructors: "Instructor: Ronald Miller",
  },
  {
    name: "Economics of Information",
    time: "Spring 2017",
    instructors: "Instructor: Pierre-Andre Chiappori",
  },
  {
    name: "Intermediate Microeconomics",
    time: "Fall 2016",
    instructors: "Instructor: Caterina Musatti",
  },
];

class TeachingItems extends React.Component {
  render() {
    return (
      <Flex
        sx={{
          bg: "white",
          p: [2, 3, 3],
          ml: [1, 1, 2],
          pt: [2, "8pt", "8pt"],
          pb: [2, 3, 3],
          flexGrow: 1,
          flexDirection: "column",
          // borderStyle: 'solid',
          bg: "plainLight",
          // borderWidth: "1px",
        }}
      >
        <Flex
          sx={{
            fontSize: ["16px", "16px", "18px"],
            p: 1,
            py: "12px",
            pb: "8pt",
            mb: 2,
            ml: 1,
            mr: 2,
            mb: [0, 1, 1],
            mx: [1, 1, 2],
            fontWeight: "bold",
            borderBottom: "2px solid",
            // borderBottomColor: 'plainLight',
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ pr: 3 }}>Teaching Fellow Appointments</Box>
          <Box
            sx={{
              //  bg:'blue',
              textAlign: "right",
            }}
          >
            Columbia University
          </Box>
        </Flex>
        {_.map(this.props.items, (item) => (
          <TeachingItem item={item} />
        ))}
      </Flex>
    );
  }
}

const TeachingPage = () => (
  <Box>
    <span id="teaching"></span>
    <Box
      sx={{
        p: [2, 3, 3],
        pl: 0,
        ml: 0,
        pt: 1,
        fontSize: ["10px", "12px", "15px"],
        borderTop: "2px solid",
        borderTopColor: "black",
      }}
    >
      <h1>Teaching</h1>
      <br />
      <TeachingItems items={myTeachingItems} />
    </Box>
  </Box>
);

function TeachingItem({ item }) {
  return (
    <Flex
      sx={{
        justifyContent: "space-between",
        flexGrow: 1000,
        // borderTop: '2px solid',
        pb: [0, 0.5, 1],
        pt: [0, 0, 1],
        // bg: 'blue',
        // borderTopColor: 'plainLight',
        flexDirection: "row",
      }}
    >
      <Flex
        sx={{
          p: 2,
          ml: 1,
          // p: 0, m: 0,
          // height: 100,
          // width: 50,
          flexDirection: "column",
          overflowClipBox: "content-box",
          // bg: 'blue',
          color: "black",
          fontSize: tableItemSizes,
          // flexGrow: 0,
          // minWidth: '30vw',
          // justifyContent: 'space-between',
        }}
      >
        <Box>{item.name}</Box>
        <Box
          sx={{
            pt: 1,
            pl: 2,
            fontSize: smallTextSizes,
            color: "black",
            // fontFamily: 'Roboto Slab, serif'
          }}
        >
          {item.instructors}
        </Box>
      </Flex>
      <Flex
        sx={{
          p: 2,
          // bg : 'red',
          flexGrow: 100,
          justifyContent: "flex-end",
        }}
      >
        <Flex
          sx={{
            color: "plainDark",
            ":hover": {
              color: "oran",
            },
            fontSize: smallTextSizes,
            textAlign: "center",
            mr: [1, 3, 4],
            // bg: 'white'
          }}
        >
          {_.map(item.attachments, (link) => (
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                color: "inherit",
                fontWeight: "normal",
              }}
            >
              teaching notes <FontAwesomeIcon icon={faGoogleDrive} />
            </a>
          ))}
        </Flex>
        <Box
          sx={{
            fontSize: tableItemSizes,
            textAlign: "right",
            // fontFamily: 'Roboto Slab, serif'

            // minWidth: 80
            // bg: 'blue',
          }}
        >
          <span>{item.time}</span>
        </Box>
      </Flex>
    </Flex>
  );
}

export default TeachingPage;
